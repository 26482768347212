.navbar {
    margin: 0;
    padding: 0;
    width: 100%;
    top: 0;
    overflow: hidden;
    position: fixed;
    z-index: 1;
}

.container-fluid {
    height: 5em;
    width: 100%;
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-items: center;
    background-color: #00336622
}

.navbar-brand {
    height: 3.1em;
}

.logoMob {
    width: 3em;
    margin-left: 1em;
}

.navbar-toggler {
    padding: 0px;
    margin-right: 1.5em;
    border: 0px;
    background-color: none;
}

.navbar-toggler-icon {
    width: 35px;
    background-image: url(../images/hamburger.svg);
}

.offcanvas-backdrop {
    opacity: 0 !important;
}

.offcanvas-body {
    height: 90%;
}

.menu-showed {
    max-width: 18em;
    backdrop-filter: blur(9px);
    background-color: var(--primary-color) 0.3;
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.2);
    display: block;
    flex-direction: row;
}

.offcanvas-header {
    margin: 1em 0px 1em 0px;
}

.btn-close{
    margin: 0.5em 1em 0px 0px !important;
}

.offcanvas-title {
    font-size: xx-large;
    margin-left: 0.6em;
    font-weight: 600;
}

.items-menu-mob {
    margin-left: 0.7em;
    margin-bottom: 200px !important;
}

.item-menu-div {
    float: left;
    display: flex;
    width: 100%;
    margin: 0.5em;
}

.nav-link-mob {
    text-align: left;
    width: 10em;
    padding-left: 1.2em;
}

.item-name {
    font-size: large;
}

.menu-img {
    width: 2.5em;
}

.list-extra-items{   
    text-align: center;
    float: left;
    display: flex;
}

.extra-item-mob {
    width: 7em;
}


.extra-sett-img {
    width: 2.5em;
}