:root {
    --primary-color: #FFFFFF; /* sfondi del contenuto e dei bottoni */
    --secondary-color: #003366; /* titoli, sottotitoli e paragrafi */
    --secondary-color-15: #00336615; /* secondary-color con opacità al 15% --> per switchbutton interni a Regola i valori */
    --secondary-color-80: #00336680; /* secondary-color con opacità 80 --> per sidebar lista elementi */
    --third-color: #99CC33; /* CTA e bottoni */
    
    /* sfondi */
    --sidebar-color: #E9EFF5;
    --main-color: #F5F9FC;
}