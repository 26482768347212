@media screen and (min-width: 800px){

    .container-value {
      background-color: var(--main-color);
      border-radius: 15px;
      padding: 1.5em;
    }

    .lastHour-settings {
      width: 29em;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .container-value h3 {
      text-align: center;
      border-bottom: 1px solid #bebebe;
      line-height: 0.1px;
      margin: 0.2em 0 0.8em;
    }
  
    .container-value h3 span {
      padding: 0 1em;
      background-color: var(--main-color);
    }
  
    .checkbox-container {
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  
    .checkbox-container label {
      padding: 1.5em 1em 2em 1em;
      align-items: center;
      text-align: center;
      width: 8em;
    }
  
    .checkbox-container input[type="checkbox"] {
      display: block;
      transform: scale(1.6);
      margin-left: 40%;
      margin-bottom: 0.8em;
    }

    .checkbox-value {
      -webkit-appearance: none;
      background-color: var(--main-color);    
      border-radius: 4px;

      display: inline-block;
      position: relative;
      width: 1.3em;
      height: 1.3em;
    }

    .checkbox-value:active, .checkbox-value:checked:active {
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1)
    }
  
    .checkbox-value:checked:after {
      content: '\2714';
      font-size: smaller;
      position: absolute;
      left: 0.3em;
      color: var(--main-color);
    }

    #first-button{
      border: 1px solid rgb(96, 165, 221);
    }

    #second-button{
      border: 1px solid rgba(0, 51, 102);
    }

    #third-button{
      border: 1px solid rgb(153, 204, 51);
    }

    #first-button:checked{
      background-color: rgb(96, 165, 221);
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    }

    #second-button:checked{
      background-color: rgba(0, 51, 102);
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    }

    #third-button:checked{
      background-color: rgb(153, 204, 51);
      box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    }

    .buttons {
      display: flex;
      margin: 1em 0 0 0;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  
    .label-buttons {
      padding: 0 2em 0 2em;
      width: 20em;
    }
}

@media screen and (max-width: 500px){

  .container-value {
    background-color: var(--main-color);
    border-radius: 15px;
    padding: 1.5em;
    margin-top: -1em;
  }

  #control-text {
    margin-top: 1.2em;
  }

  .container-value h3 {
    font-size: 2em;
    text-align: center;
    border-bottom: 1px solid #bebebe;
    line-height: 0.1px;
    margin: 0.2em 0 0.8em;
  }

  .container-value h3 span {
    padding: 0 1em;
    background-color: var(--main-color);
  }

  .checkbox-container {
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .checkbox-container label {
    margin: 1.2em 0.5em;
    align-items: center;
    text-align: center;
    width: 5.5em;
  }

  .checkbox-container input[type="checkbox"] {
    display: block;
    transform: scale(1.6);
    margin-left: 40%;
    margin-bottom: 0.8em;
  }

  .checkbox-value {
    -webkit-appearance: none;
    background-color: var(--main-color);    
    border-radius: 4px;

    display: inline-block;
    position: relative;
    width: 1.3em;
    height: 1.3em;
  }

  .checkbox-value:active, .checkbox-value:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1)
  }

  .checkbox-value:checked:after {
    content: '\2714';
    font-size: smaller;
    position: absolute;
    left: 0.3em;
    color: var(--main-color);
  }

  #first-button{
    border: 1px solid rgb(96, 165, 221);
  }

  #second-button{
    border: 1px solid rgba(0, 51, 102);
  }

  #third-button{
    border: 1px solid rgb(153, 204, 51);
  }

  #first-button:checked{
    background-color: rgb(96, 165, 221);
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
  }

  #second-button:checked{
    background-color: rgba(0, 51, 102);
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
  }

  #third-button:checked{
    background-color: rgb(153, 204, 51);
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
  }

  .buttons {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .label-buttons {
    padding: 0 1em 0 1em;
    width: 20em;
    margin-bottom: -1em;
  }
}