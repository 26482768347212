.form-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-box {
  background: var(--primary-color);
  filter: drop-shadow(0 0 7px var(--secondary-color-15));
  width: 9.2em;
  height: 4em;
  position: relative;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-box-other{
  background: var(--primary-color);
  filter: drop-shadow(0 0 7px var(--secondary-color-15));
  width: 15em;
  height: 6em;
  position: relative;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#instant-button-box {
  background: var(--sidebar-color);
  filter: none;
}

.toggle-btn {
  margin-right: 1em;
  margin-left: 1.2em;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
}

.toggle-btn-other {
  margin-right: 2.5em;
  margin-left: 2.5em;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
}

#btn {
  margin-right: 45%;
  top: 10%;
  position: absolute;
  width: 45%;
  height: 80%;
  background: var(--third-color);
  border-radius: 4px;
  transition: .5s;
  text-align: center;
}

#btn-other{
  background-color: var(--third-color);
  height: 80%;
  width: 45%;
  margin-right: 7em;
  border-radius: 4px;
  position: absolute;
  transition: .5s;
  text-align: center;
}
