@media screen and (min-width: 800px) {
  /* DESKTOP */
  
  .charts {
    display: flex;
    margin-bottom: 5em;
  }
  
  .charts-container.disabled {
    filter: blur(2px);
    pointer-events: none;
    opacity: 0.4;
  }

  .monitoraggio-content {
    background-color: var(--main-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;
    width: 100em;
    height: 95%;
    margin-top: 2em;
    margin-left: 10em;
    padding: 3em;
  }

  .div_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select{
    position:relative;
    padding: 0.6em;
    width: 120%;
    font-size: medium;
    font-weight: 600;
    border-radius: 7px;
    background-color: var(--secondary-color-15);
    border: none;

    appearance: none;
    background-image: url('../images/freccia-select.png');
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%;
    background-size: 1.2em auto;
    overflow: hidden;
  }

  .select-item {
    background-color: var(--secondary-color-15);
    font-size: medium;
    
  }

  .div_select-devices {
    margin-right: 2.6em;
  }

  #text-start {
    font-weight: 600;
    font-size: 2.3em;
    line-height: 1.5em;
  }

  #secondary-text,
  #instant-text {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 1.7em;
  }

  .left-side {
    margin-top: 1.5em;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 26em;
    padding: 2.4em;
    margin-right: 2em;
    display: inline-block;
    vertical-align: top;
  }

  .right-side {
    margin-top: 1.5em;
    width: 60em;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;
  }

  .lastHour-more {
    display: flex;
  }

  .set-value {
    margin-bottom: 30px;
  }

  .yesterday-left .checkbox-container {
    width: 300px !important;
    margin-left: -25px;
  }

  .yesterday-left .buttons,
  .yesterday-left .label-buttons {
    width: 1em !important;
    padding: 1em !important;
    margin-left: -17px;
  }

  .CER-chart {
    padding: 1.7em;
    height: 23.95em;
    align-items: center;
    text-align: center;
  }

  .left-side-lastDay{
    margin: 1.4em 0 0 0;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 32em;
    height: 24em;
    padding: 1em;
    margin-right: 2em;
    display: flex;
    flex-direction: column;
  }

  .left-side-lastWeek{
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 32em;
    height: 24em;
    padding: 1em;
    margin-right: 2em; 
    margin-top: 12em;
    display: flex;
    flex-direction: column;
  }
}


@media screen and (max-width: 500px){

  /* MOBILE */

  .charts-container.disabled {
    filter: blur(2px);
    pointer-events: none;
    opacity: 0.4;
  }

  .div_header {
    padding-top: 6em;
    text-align: center;
    align-items: center;
  }

  #text-start{
    font-size: 3em;
    font-weight: 600;
    line-height: 1.3em;
  }

  #secondary-text {
    font-size: 1.7em;
    font-weight: 500;
    margin-bottom: 1em;
    text-align: center;
  }

  .select{
    position:relative;
    padding: 0.6em;
    width: 70%;
    font-size: medium;
    font-weight: 600;
    border-radius: 7px;
    background-color: var(--secondary-color-15);
    border: none;

    appearance: none;
    background-image: url('../images/freccia-select.png');
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%;
    background-size: 1.2em auto;
    overflow: hidden;
  }

  .select-item {
    background-color: var(--secondary-color-15);
    font-size: medium;
    
  }

  .div_select-devices {
    margin-right: 1em;
    margin-left: 1em;
  }

  .left-side {
    margin: 1em;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 93.5%;
    padding: 1.5em;
    display: inline-block;
    vertical-align: top;
  }

  h5 {
    font-size: 1.6em;
    margin-bottom: 1.2em;
  }

  .left-side-lastDay {
    margin: 0em 1em 1em 1em;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 93.5%;
    height: 49em;
    padding: 1em 1em;
    display: inline-block;
    vertical-align: top;
  }

  .left-side-lastWeek{
    margin: 0em 1em 1em 1em;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 93.5%;
    height: 76em;
    padding: 1em 1em;
    display: inline-block;
    vertical-align: top;
  }

  .isto-text {
    margin-top: -4em;
  }

}