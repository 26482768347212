@media screen and (min-width: 800px) {
  /* DESKTOP */

  .line-chart-div {
    width: 100%;
    min-width: 50%;
    height: 100%;
    padding: 2em;
    display: inline-block;
    vertical-align: top;

    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .line-graph {
    margin-top: 2em;
    margin-left: -0.4em;
    width: 62em;
    height: 15em !important;
  }

  #div-last-hour {
    width: 100em;
  }

  #graph-prod {
    width: 102%;
  }

  #div-yesterday {
    width: 57em;
  }

  #div-week {
    height: 100%;
  }

  #graph-selected {
    margin-left: 2em;
    width: 95%;
  }

  .lastHour-more {
    display: flex;
    justify-content: space-between;
    padding-top: 3.7em;
    margin-right: 1.7em;
    margin-left: 2.7em;
  }

  .lastHour-info {
    width: 27em;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  #myChart {
    height: 15em;
  }
}

@media screen and (max-width: 500px) {

  /* MOBILE */

  .line-chart-div {
    margin: 1em 1em 3em 1em;
    background-color: var(--primary-color);
    box-shadow: 0px 0px 30px rgba(0, 51, 102, 0.05);
    border-radius: 20px;

    align-items: center;
    text-align: center;
    justify-content: center;

    width: 93.5%;
    padding: 1.5em;
    display: inline-block;
    vertical-align: top;
  }

  .line-graph {
    margin-top: 2em;
    margin-left: -1.1em;
    width: 108%;
    height: 15em !important;
  }

  .lastHour-info {
    margin-top: 2em;
    width: 100%;
    position: relative;
    top: 25em;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .controlValue-div {
    position: relative;
    bottom: 23em;
  }

  .controlValueYest-div{
    position: relative;
    top: 20em;
  }

  #div-prod{
    position: relative;
    bottom: 31em;
  }

  .yest-charts-div{
    position: relative;
    bottom: 68em;
    height: 10em;
    width: 83%;
    text-align: center;
    margin-left: 2em;
  }

  .controlValueWeek-div{
    position: relative;
    top: 47em;
  }

  .week-charts-div{
    position: relative;
    bottom: 76em;
    width: 83%;
    text-align: center;
    align-items: center;
    margin-left: 2em;
  }

  #div-week {
    height: 80%;
  }
}