@import url('https://fonts.cdnfonts.com/css/graphik-trial');

* {
    font-family: 'Graphik Trial', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #003366;
}

.content {
    float: left;
}

.icons-menu {
    color: var(--third-color);
}