.menuDesktop {
    background-color: var(--sidebar-color);
    width: 125px;
    height: 100vh;
    text-align: center;
    float: left;
    position: fixed;
    z-index: 8;
    
}

.img-fluid {
    width: 100%;
}

.logo {
    margin-top: 30px;
    margin-bottom: 80px;
    margin-left: 35px;
    width: 70px;
}

.nav {
    display: block !important;
}

.items-menu {
    margin-bottom: 180px !important;
}

.item-menu {
    padding: 5px !important;
    margin-left: 10px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.item-menu:hover {
    background: rgba(0, 51, 102, 0.1);
    border-radius: 12px;
}

.nav-link {
    display: block;
    cursor: pointer;
    text-decoration: none;
}

p {
    padding: 0;
    margin: 0;
}

.extra-item {
    padding: 5px;
}


#active {
    background: rgba(0, 51, 102, 0.1);
    border-radius: 12px;
}
