/* MOBILE */

body {
    margin: 0;
    height: 100vh;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    background-color:  var(--sidebar-color);
}

.background-images {
    float: left;
    position: absolute;
}

.circles {
    margin-top: 20%;
}

.omino {
    display: none;
}

.logoImg {
    padding-left: 4%;
    margin: 2%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.not-error {
    height: 10px;
    margin: 0;
}

.container {
    display: block;
    border-radius: 20px;
    position: absolute; 
    width: 100%;
    height: auto;
    z-index: 15;
    top: 180px;
    left: 20%;
    margin: -100px 0 0 -150px;
}

.row {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.title-CER {
    font-weight: bold;
}

.login-box {
    background-color: var(--main-color);
    box-shadow: 0px 3.41481px 25.6111px rgba(0, 51, 102, 0.05);
    border-radius: 17.0741px;
    margin-top: 10%;
    width: 90%;
}

.text-login {
    margin: 8% auto 2% auto;
    font-size: 18px;
}

.error-text {
    color:red;
    font-size: 12px;
}

fieldset {
    display: inline-block;
    width: 100% !important;
    padding: 0px;
    margin: 0px;
    margin-bottom: 2%;
    /* border: 2px solid var(--secondary-color); */
    border-radius: 12px;
    position: relative;
}

legend {
    text-align: left;
    padding: 5px;
    margin: -10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
}

.input-log {
    background-color: var(--main-color);
    border-radius: 12px;
    padding-left: 5%;
    border: 0px;
    width: 90%;
    height: 40px;
    position: inherit;
    float: left;
    font-size: 16px;
}

.input-log:focus {
    outline: 0 !important;
}

.input-log::placeholder {
    color: rgba(0, 51, 102, 0.3);
    font-weight: 300;
    font-size: 16px;
}

.eye-pass {
    margin-top: 2%;
    margin-right: 2%;
    cursor: pointer;
}

.extra-settings {
    text-align: left;
    align-items: left;
    justify-content: left;
}

.remember-me {
    margin: 3% 0 3% 2%;
    float: left;
    max-width: 49%;
    text-align: left;
    /* padding: 10px 10px 10px 25px; */ 
}

.checkbox-button {
    width: 19px;
    height: 19px;
    margin-top: -5px;
    vertical-align: middle;
    cursor: pointer;
}

.lab-check {
    padding-left: 7px;
    font-size: 15px;
}

.forgot-pass {
    max-width: 47%;
    margin-right: 2%;
    text-decoration: underline;
    cursor: pointer;
}

.text-forgot-pass {
    color: var(--secondary-color);
}

.button-login {
    border: 1px solid var(--secondary-color);
    margin: 5%;
    width: 80%;
    background-color: var(--secondary-color);
    padding: 2%;
}

.text-button {
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}


@media screen and (max-width: 374px) and (max-height: 799px) {

    /* MINI MOBILES */

    .row-as {
        margin-left: 29%;
        width: 100%;
    }
    
    .input-log {
        width: 70%;
    }

    .input-log::placeholder {
        color: var(--main-color);
    }

    .eye-pass {
        margin-top: 3%;
        margin-left: 5%;
    }

    .extra-settings{
        align-items: center;
        justify-content: center;
    }

    .remember-me {
        padding: 0;
        text-align: center;
        min-width: 100%;
    }

    .checkbox-button {
        width: 21px;
        height: 21px;
    }

    .lab-check{
        font-size: 16px;
        text-align: center;
    }

    .forgot-pass {
        text-align: center;
        max-width: 100%;
    }
}

@media screen and (min-width: 375px) and (max-width: 399px) and (max-height: 849px) {

    /* MOBILES */

    .row-as {
        margin-left: 22%;
        width: 100%;
    }

    .input-log {
        width: 85%;
    }

    .remember-me {
        padding: 0;
        text-align: center;
        max-width: 60%;
    }

    .checkbox-button {
        width: 20px;
        height: 20px;
    }

    .lab-check{
        font-size: 15px;
        text-align: center;
    }

    .forgot-pass {
        padding: 0;
        text-align: right;
        max-width: 30%;
    }

}

@media screen and (min-width: 400px) and (max-width: 899px) {

    /* MAX MOBILES */

    .row-as {
        margin-left: 17%;
        width: 100%;
    }

    .input-log {
        width: 85%;
    }

    .remember-me {
        padding: 0;
        text-align: center;
        max-width: 55%;
    }

    .checkbox-button {
        width: 20px;
        height: 20px;
    }

    .lab-check{
        font-size: 16px;
        text-align: center;
    }

    .forgot-pass {
        text-align: right;
        max-width: 35%;
    }

}



@media screen and (min-width: 1025px) and (max-width: 1204px) {

    .row-as {
        margin-top: 20%;
        margin-left: 30%;
        width: 90%;
    }

    .login-box {
        max-width: 90%;
        min-height: 60vh;
    }

    .circles {
        margin: 0;
        width: 150%;
    }

    .container {
        border-radius: 20px;
        position: absolute;
        width: 60%;
        z-index: 15;
        top: 30%;
    }

    .text-login {
        margin: 4% 0 5% 0;
    }

    fieldset {
        max-width: 80%;
    }

    .login-box {
        margin-top: 5%;
        width: 95% !important;
    }

    .extra-settings {
        margin: 2% 5% 0 5%;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .remember-me {
        min-width: 35%;
        margin-right: 7%;
        text-align: right;
    }

    .checkbox-button {
        width: 20px;
        height: 20px;
    }

    .forgot-pass {
        min-width: 40%;
        padding: 0;
        margin-right: 10%;
    }

    .lab-check{
        font-size: 14px;
        text-align: left;
    }

    .text-forgot-pass {
        font-size: 14px;
    }
    
}



@media screen and (min-width: 1205px) {
    
    /* DESKTOP */

    body {
        margin: 0;
        height: 100vh;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        background-color:  var(--sidebar-color);
        
    }

    .background-images {
        width: 100%;
        height: 100vh;
    }

    .circles {
        width: 100%;
        margin: 0;
        position: absolute;
    }
/* 
    .row-as {
        margin-top: 20%;
    } */

    .omino {
        display: block;
        margin: 0;
        position: relative;
        z-index: 2;
        width: 50%;
    }

    .container {
        position: absolute;
        width: 50%;
        z-index: 1;
        top: 15%;
        left: 40%;
        background-color: var(--main-color);
        margin: 0;
        margin-top: 5%
    }

    .logoImg {
        margin: 2%;
        padding: 3% 0 0 3%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .login-box {
        background-color: var(--primary-color);
        box-shadow: 0px 3.41481px 25.6111px rgba(0, 51, 102, 0.05);
        border-radius: 17.0741px;
        margin: 2% 5% 5% 5%;
        width: 80%;
    }

    .text-login {
        margin: 8% auto 2% auto;
        font-size: 20px;
    }

    .error-text {
        color:red;
        font-size: 12px;
    }

    fieldset {
        display: inline-block;
        width: 90% !important;
        padding: 0px;
        margin: 0px;
        margin-bottom: 2%;
        /* border: 2px solid var(--secondary-color); */
        border-radius: 12px;
        position: relative;
    }
    
    legend {
        text-align: left;
        padding: 5px;
        margin: -10px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
    }
    
    .input-log {
        background-color: var(--primary-color);
        border-radius: 12px;
        margin: 0px 0px 0px 6%;
        padding: 0px;
        border: 0px;
        width: 80%;
        height: 40px;
        position: inherit;
        float: left;
    }
    
    .input-log:focus {
        outline: 0 !important;
    }
    
    .input-log::placeholder {
        color: rgba(0, 51, 102, 0.3);
        font-weight: 300;
        font-size: 16px;
    }

    .extra-settings {
        text-align: left;
        align-items: left;
        justify-content: left;
    }
    
    .remember-me {
        float: left;
        max-width: 49%;

        margin-right: 7%;
        text-align: right;
        /* padding: 10px 10px 10px 25px; */ 
    }

    .still-connect {
        width: 100%;
    }
    
    .checkbox-button {
        width: 23px;
        height: 23px;
        margin-top: -5px;
        vertical-align: middle;
        cursor: pointer;
    }
    
    .forgot-pass {
        min-width: 30%;
        padding: 0;
        margin-right: 10%;
    }

    .lab-check {
        padding-left: 4%;
        font-size: 16px;
    }

    .text-forgot-pass {
        font-size: 16px;
    }
}