.container {
    position: relative !important;
    top: 0;
    bottom: 0;
}

.container-controllo {
    position: absolute;
    display: block;
    width: 500px;
    height: 100px;
    background-color: var(--secondary-color);
}