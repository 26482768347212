
.vertical-scroll {
  background-color: var(--main-color);
  border-radius: 15px;
  height: 18em;
  width: 21.3em;
  margin-top: 0.7em;
}

.scroll-content {
  overflow: hidden;
  overflow-y: scroll; 
  height: 17.9em;
}

/* Stile della scrollbar */
.scroll-content::-webkit-scrollbar {
  width: 0.7em; /* larghezza della scrollbar */
  border-radius: 6px;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color-80); /* colore dell'impugnatura della scrollbar */
  border-radius: 6px;
}

.scroll-content::-webkit-scrollbar-track {
  background-color: var(--secondary-color-15); /* colore della traccia della scrollbar */
  border-radius: 6px;
}

.scroll-elements{
  width: 20em;
  display: flex;
  margin-bottom: 0.7em;
}

.element-image{
  flex: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inner-image{
  margin: auto;
  width: 3.5em;
}

.element-description{
  flex: 1.7;
  display: inline-block;
  text-align: left;
}

.element-title{
  font-size: medium;
  font-weight: 700;
}

.element-range-label{
  font-size: small;
}

.rounder-divider{
  border: 0.1 solid black;
  width: 85%;
  margin-bottom: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 5px;
}

@media screen and (max-width: 800px){

  .vertical-scroll {
    background-color: var(--main-color);
    border-radius: 15px;
    height: 18em;
    width: 100%;
    margin-top: 0.7em;
  }
}