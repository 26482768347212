@media screen and (min-width: 800px) {
  /* DESKTOP */

  .container-tack {
    height: 20em;
    position: relative;
  }

  .container-track-consumo {
    margin-top: -12.5em;
  }

  .container-track-info{
    margin-bottom: 0.5em;
  }

  .container-track-info:hover {
    cursor: pointer;
  }

  .container-track-refresh:hover {
    cursor: pointer;
  }

  .hover-image-container {
    position: relative;
    display: inline-block;
  }

  .hover-image {
    transition: opacity 0.3s ease;
  }

  .hover-image:hover {
    opacity: 0.7;
  }

  .hover-text {
    width: 20em;
    position: absolute;
    transform: translateX(-20%);
    background-color: var(--sidebar-color);
    padding: 0.5em;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0s linear 0.3s;
    text-align: left;
    border-radius: 7%;
    z-index: 1;
  }

  .hover-image-container:hover .hover-text {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  .hover-text-inner {
    font-size: 1em;
  }

  #refresh-image{
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 500px){
  .hover-image-container {
    display: none;
  }

  #consumi-text{
    font-size: medium;
  }

  #consumi-value{
    margin-top: -0.2em;
    font-size: 2.8em;
  }

  .container-track-consumo {
    margin-top: -12em;
  }

  .form-box{
    margin-top: 1em;
    margin-bottom: 2em;
  }

  #scroll-text {
    margin-bottom: 0.8em;
  }
}